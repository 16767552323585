@tailwind base;
@tailwind components;
@tailwind utilities;

/* merriweather-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/merriweather-v30-latin-regular.woff2")
    format("woff2");
}
/* merriweather-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/merriweather-v30-latin-700.woff2") format("woff2");
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/open-sans-v35-latin-regular.woff2") format("woff2");
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/open-sans-v35-latin-700.woff2") format("woff2");
}
